.loader-container {
  position: absolute;
  width: 50vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 65px;
    aspect-ratio: 1;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    border-radius: 50px;
    box-shadow: 0 0 0 3px inset #bfa9cb;
    animation: l4 2.5s infinite;
  }
  .loader:after {
    animation-delay: -1.25s;
  }
  @keyframes l4 {
    0% {
      inset: 0 35px 35px 0;
    }
    12.5% {
      inset: 0 35px 0 0;
    }
    25% {
      inset: 35px 35px 0 0;
    }
    37.5% {
      inset: 35px 0 0 0;
    }
    50% {
      inset: 35px 0 0 35px;
    }
    62.5% {
      inset: 0 0 0 35px;
    }
    75% {
      inset: 0 0 35px 35px;
    }
    87.5% {
      inset: 0 0 35px 0;
    }
    100% {
      inset: 0 35px 35px 0;
    }
  }
}

.loader-userName {
  .loader {
    width: 120px;
    height: 20px;
    background:
      linear-gradient(#000 50%, #0000 0),
      linear-gradient(#0000 50%, #000 0),
      linear-gradient(#000 50%, #0000 0),
      linear-gradient(#0000 50%, #000 0),
      linear-gradient(#000 50%, #0000 0),
      linear-gradient(#0000 50%, #000 0) #ddd;
    background-size: calc(100% / 6 + 1px) 200%;
    background-repeat: no-repeat;
    animation: l12 2s infinite;
  }
  @keyframes l12 {
    0% {
      background-position:
        calc(0 * 100% / 5) 100%,
        calc(1 * 100% / 5) 0%,
        calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%,
        calc(4 * 100% / 5) 100%,
        calc(5 * 100% / 5) 0%;
    }
    16.67% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 0%,
        calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%,
        calc(4 * 100% / 5) 100%,
        calc(5 * 100% / 5) 0%;
    }
    33.33% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 100%,
        calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%,
        calc(4 * 100% / 5) 100%,
        calc(5 * 100% / 5) 0%;
    }
    50% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 100%,
        calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 0%,
        calc(4 * 100% / 5) 100%,
        calc(5 * 100% / 5) 0%;
    }
    66.67% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 100%,
        calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%,
        calc(4 * 100% / 5) 100%,
        calc(5 * 100% / 5) 0%;
    }
    83.33% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 100%,
        calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%,
        calc(4 * 100% / 5) 0%,
        calc(5 * 100% / 5) 0%;
    }
    100% {
      background-position:
        calc(0 * 100% / 5) 0%,
        calc(1 * 100% / 5) 100%,
        calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%,
        calc(4 * 100% / 5) 0%,
        calc(5 * 100% / 5) 100%;
    }
  }
}
